import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1080.000000 538.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,538.000000) scale(0.100000,-0.100000)">

<path d="M3917 4639 c-11 -38 -7 -2381 3 -2471 25 -214 187 -374 390 -386 47
-3 92 0 115 8 48 16 110 72 138 124 21 40 22 51 22 361 0 176 -6 433 -13 570
-23 456 -32 638 -37 800 l-5 160 308 -925 c169 -509 316 -938 327 -955 29 -46
66 -85 110 -114 37 -24 48 -26 145 -26 121 0 160 15 223 84 52 57 77 112 141
311 192 587 538 1631 541 1628 2 -2 0 -71 -5 -153 -5 -83 -14 -262 -20 -400
-6 -137 -15 -324 -21 -415 -5 -91 -12 -284 -15 -430 -7 -284 -2 -337 46 -429
30 -59 108 -134 175 -167 54 -26 70 -29 160 -29 77 0 107 4 133 18 50 27 111
93 136 146 l21 46 0 1330 0 1330 -245 3 c-289 3 -351 -5 -460 -59 -96 -48
-194 -148 -259 -263 -45 -80 -68 -149 -395 -1206 -59 -190 -111 -356 -115
-369 -9 -30 -37 -50 -50 -36 -5 6 -51 143 -101 305 -325 1045 -315 1016 -434
1195 -108 162 -258 293 -407 356 -128 55 -209 71 -385 76 l-161 6 -6 -24z"/>
<path d="M2960 4639 c-296 -34 -543 -226 -705 -546 -18 -37 -68 -165 -110
-283 -42 -118 -98 -276 -125 -350 -102 -284 -191 -534 -280 -785 -51 -143
-144 -404 -206 -579 -63 -175 -114 -323 -114 -329 0 -16 295 -1 364 18 200 56
348 188 467 418 l50 97 461 -2 461 -3 27 -85 c14 -47 51 -167 81 -267 61 -207
49 -194 160 -173 162 30 302 155 368 330 21 54 25 83 25 175 1 120 -2 130
-167 585 -25 69 -102 285 -172 480 -70 195 -145 405 -167 465 -22 61 -53 148
-70 195 -107 302 -221 618 -229 633 -10 18 -9 18 -119 6z m-187 -801 c4 -21
193 -650 253 -843 14 -44 27 -88 30 -97 5 -17 -14 -18 -296 -18 -238 0 -301 3
-298 13 3 6 70 229 149 494 79 264 147 479 150 477 4 -2 9 -14 12 -26z"/>
<path d="M8105 4639 c-390 -35 -714 -252 -894 -598 -49 -95 -105 -259 -133
-389 -17 -82 -21 -147 -25 -412 -5 -291 -3 -325 17 -445 53 -313 152 -519 342
-713 198 -201 445 -303 793 -328 226 -16 609 47 767 127 198 100 322 233 385
411 25 69 27 87 31 303 2 143 0 253 -7 290 -32 177 -186 346 -355 389 -47 12
-130 16 -417 16 -198 1 -359 -2 -359 -5 0 -3 0 -106 0 -230 l0 -225 235 0 235
0 -2 -222 -3 -223 -55 -33 c-88 -52 -140 -64 -300 -69 -115 -4 -158 -1 -209
12 -244 63 -376 267 -412 634 -15 156 -6 560 15 664 35 174 89 297 165 374 98
99 197 143 337 151 108 5 182 -9 264 -49 100 -51 171 -156 205 -308 l17 -73
82 4 c92 6 149 23 208 65 110 76 179 253 156 398 -41 260 -344 445 -793 484
-133 12 -160 12 -290 0z"/>
<path d="M1335 1506 c-41 -18 -83 -69 -90 -109 -3 -18 -5 -143 -3 -279 3 -274
3 -275 78 -325 l33 -23 4047 0 4047 0 33 23 c18 12 43 35 54 50 20 27 21 40
21 302 0 261 -1 275 -21 301 -11 15 -33 37 -48 48 l-27 21 -4047 2 c-3349 2
-4052 0 -4077 -11z m983 -178 c17 -17 15 -64 -4 -82 -10 -10 -33 -16 -65 -16
l-49 0 0 -134 c0 -163 -7 -186 -53 -186 -54 0 -57 9 -57 172 l0 147 -57 3
c-50 3 -59 6 -72 30 -12 21 -13 31 -3 52 l12 26 168 0 c117 0 172 -4 180 -12z
m420 0 c17 -17 15 -64 -4 -82 -12 -13 -40 -16 -135 -16 l-119 0 0 -105 0 -105
117 0 c129 0 153 -9 153 -60 0 -47 -12 -50 -192 -50 -155 0 -168 1 -178 19 -6
12 -10 94 -10 196 0 102 4 184 10 196 10 18 23 19 178 19 117 0 172 -4 180
-12z m160 0 c8 -8 12 -58 12 -160 l0 -148 108 0 c59 0 117 -3 130 -6 27 -8 39
-46 23 -80 l-11 -24 -169 0 c-158 0 -171 1 -181 19 -6 12 -10 95 -10 200 0
150 3 182 16 195 18 19 65 21 82 4z m680 0 c20 -20 15 -76 -7 -88 -11 -5 -72
-10 -135 -10 l-116 0 0 -105 0 -105 109 0 c137 0 161 -8 161 -53 0 -18 -5 -38
-12 -45 -15 -15 -325 -17 -348 -2 -12 7 -16 43 -18 190 -5 247 -19 230 191
230 113 0 167 -4 175 -12z m430 0 c20 -20 15 -76 -7 -88 -11 -5 -55 -10 -98
-10 -43 0 -89 -6 -103 -13 -35 -18 -53 -77 -40 -128 15 -56 40 -69 132 -69
104 0 128 -10 128 -54 0 -53 -15 -59 -144 -55 -110 4 -116 5 -153 35 -56 45
-73 85 -73 176 0 115 25 162 105 199 48 22 233 27 253 7z m353 1 c143 -48 193
-227 96 -344 -87 -106 -263 -98 -342 14 -25 36 -30 53 -33 114 -2 63 1 78 25
122 48 85 158 126 254 94z m341 -56 l68 -67 68 67 c68 68 106 84 130 55 6 -8
12 -94 14 -206 3 -209 3 -212 -52 -212 -44 0 -50 16 -50 134 l0 110 -43 -42
c-54 -53 -81 -54 -127 -7 -19 19 -38 35 -42 35 -4 0 -8 -46 -8 -103 0 -67 -4
-107 -12 -115 -20 -20 -76 -15 -88 7 -6 12 -10 94 -10 196 0 102 4 184 10 196
7 13 21 19 43 19 26 0 44 -12 99 -67z m470 0 l68 -67 68 67 c49 49 75 67 94
67 54 0 58 -15 58 -211 0 -105 -4 -188 -10 -200 -12 -22 -68 -27 -88 -7 -8 8
-12 48 -12 115 0 57 -4 103 -8 103 -4 0 -23 -16 -42 -35 -22 -22 -43 -35 -60
-35 -17 0 -38 13 -60 35 -19 19 -37 35 -40 35 -3 0 -7 -46 -8 -102 -2 -56 -8
-108 -14 -115 -17 -21 -65 -16 -82 9 -13 18 -16 55 -16 193 0 138 3 175 16
193 28 40 62 29 136 -45z m426 55 c8 -8 12 -50 12 -122 0 -161 27 -204 115
-182 33 8 38 28 44 169 6 137 10 147 62 147 45 0 49 -14 49 -159 0 -155 -14
-201 -70 -241 -61 -44 -185 -40 -241 7 -50 42 -61 83 -67 229 -4 113 -2 132
12 147 20 20 67 22 84 5z m449 -20 c16 -18 58 -68 92 -112 l61 -79 0 92 c0 51
5 101 10 112 12 22 68 27 88 7 8 -8 12 -68 12 -204 0 -212 -2 -218 -61 -212
-29 3 -45 18 -119 109 l-85 106 -2 -86 c-4 -127 -6 -131 -53 -131 -29 0 -42 5
-50 19 -6 12 -10 95 -10 200 0 150 3 182 16 195 26 26 67 20 101 -16z m411 20
c8 -8 12 -68 12 -203 0 -135 -4 -195 -12 -203 -20 -20 -76 -15 -88 7 -6 12
-10 95 -10 200 0 150 3 182 16 195 18 19 65 21 82 4z m420 0 c20 -20 15 -76
-7 -88 -11 -5 -55 -10 -98 -10 -43 0 -89 -6 -103 -13 -35 -18 -53 -77 -40
-128 15 -56 40 -69 132 -69 104 0 128 -10 128 -54 0 -52 -15 -58 -137 -54
-135 4 -176 22 -212 92 -34 67 -34 184 0 245 37 66 98 91 224 91 65 0 105 -4
113 -12z m411 -180 c93 -184 94 -187 77 -212 l-16 -26 -188 0 c-201 0 -212 3
-212 49 0 24 147 338 172 368 6 8 25 13 42 11 29 -3 36 -12 125 -190z m488
184 c6 -4 14 -19 18 -34 11 -41 -23 -68 -85 -68 l-50 0 0 -148 c0 -102 -4
-152 -12 -160 -20 -20 -76 -15 -88 7 -5 11 -10 83 -10 160 l0 141 -46 0 c-63
0 -84 13 -84 53 0 18 5 38 12 45 13 13 324 17 345 4z m151 -5 c7 -8 12 -83 14
-199 3 -210 0 -221 -61 -216 l-36 3 -7 105 c-10 137 -4 286 12 305 15 19 64
20 78 2z m367 -8 c101 -47 153 -174 112 -272 -71 -167 -300 -186 -388 -32
-105 183 85 394 276 304z m267 9 c8 -7 48 -55 87 -105 40 -51 76 -93 79 -93 4
0 6 35 4 78 -4 102 7 132 51 132 18 0 38 -5 45 -12 8 -8 12 -68 12 -204 0
-212 -2 -218 -62 -212 -27 2 -41 15 -85 71 -28 37 -68 87 -87 110 l-36 42 0
-97 c0 -119 -3 -128 -46 -128 -58 0 -64 20 -64 215 0 138 3 175 16 193 17 25
61 30 86 10z m-5861 -158 c22 -12 27 -68 7 -88 -16 -16 -170 -16 -186 0 -18
18 -15 75 6 87 22 14 148 14 173 1z m858 -21 c13 -24 13 -29 -2 -53 -17 -25
-22 -26 -100 -26 -91 0 -107 8 -107 51 0 49 23 60 113 57 76 -3 82 -5 96 -29z"/>
<path d="M4240 1217 c-14 -7 -33 -29 -43 -50 -51 -104 78 -196 165 -118 28 25
33 36 33 76 0 40 -5 51 -33 76 -35 32 -79 38 -122 16z"/>
<path d="M7123 1090 l-31 -70 69 0 c38 0 69 2 69 5 0 10 -65 135 -70 135 -3 0
-19 -32 -37 -70z"/>
<path d="M8148 1214 c-36 -19 -48 -43 -48 -94 0 -34 6 -48 31 -71 68 -63 179
-16 179 76 0 82 -87 130 -162 89z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
